
<template>
 <el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <div class="heading">
      <h1 v-if="!galleryId">Add Gallery</h1>
      <h1 v-if="galleryId">Edit Gallery</h1></div>
  </el-col>
  <el-col :xs="6" :sm="6" :md="6" :lg="12" :xl="12">
    <div class="topsearch">
      <el-button type="primary" @click="backtogallery()">Back To Gallery</el-button>
    </div>
  </el-col>
 </el-row>
<el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
  <div v-if="showAlertAdded">
    <el-alert
      title="Gallery Added Successfully"
      type="success"
      effect="dark"
      v-if="!galleryId"
    />
    <el-alert
        title="Gallery Updated Successfully"
        type="success"
        effect="dark"
        v-if="galleryId"
    />
  </div>
  </el-col>
</el-row>
    <el-form
      ref="ruleForm"
      :model="data"
      status-icon
      :rules="rules"
      label-width="180px"
      class=" login-form"
      :label-position="labelPosition" 
    >
 <el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"><el-form-item
        label="Name"
        prop="name"
      >
       <el-input
          v-model="data.name"
          type="text"
          autocomplete="off"
        />
      </el-form-item></el-col>
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
   <el-form-item
      label="Status"
      prop="status">
      <select name="status" v-model="data.status" class="selectstatus">
    <!--Add options when value is id-->
    <option v-for="item in selectstatus" 
            :key="item.label"
            :value="item.value"
            :label="item.label"
    >
      {{item.label}}
    </option>
  </select>
  </el-form-item>
      </el-col>
</el-row>
<el-row :gutter="20">
   <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
    <el-form-item
        label="Description"
        prop="description"
      >
      <TinyMce
              v-if="loadEditor"
              v-bind:content="data.description"
              @contentChanged="updateTextAreaValue"
          >
          </TinyMce>
      </el-form-item>
  </el-col>
  
</el-row>
  <el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <el-form-item 
        label="Photo"
        prop="images"
      ><el-upload
            class="upload-demo"
            drag
            :action="imageUploadUrl"
            list-type="picture"
            :headers="imageUploadHeaders"
            name="file"
            :file-list="fileList"
            :limit="1"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="handleImageUploadSuccess"
            :on-error="handleImageUploadError"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">Drop file here or <em>click to upload</em><br>
          JPEG,PNG,JPG,GIF,SVG,WEBP files with a size less than 10M</div>
           </el-upload>
      </el-form-item>
      </el-col>
</el-row>   

<el-row :gutter="20" style="text-align: left;">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
      <el-form-item>
        <input
          id="galleryId"
          v-model="galleryId"
          name="galleryId"
          type="hidden"
        >
        <el-button
          type="primary"
          @click="submitForm('ruleForm')"
        >
          Submit
        </el-button>
        <el-button @click="resetForm('ruleForm')">
          Reset
        </el-button>
      </el-form-item>
  </el-col>
</el-row>
    </el-form>
</template>
<script>
import GalleryService from "@/api/gallery.service";
import authHeader from '@/api/auth-header';
import TinyMce from "@/components/TinyMce";
  export default {
    components: {
      TinyMce
    },
    data() {
      var checkName = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter name.'));
        } else {
          callback();
        }
      };
     
      return {
        showAlertAdded: false,
        showAlertSlugexit: false,
        labelPosition: 'top',
        galleryId: this.$route.params.galleryId,
        imageUploadUrl: process.env.VUE_APP_BASE_API,
        imageUploadHeaders: null,
		selectstatus:[{
          value: 'Active',
          label: 'Active'
        }, {
          value: 'Inactive',
          label: 'Inactive'
        }],
     fileList: [],
        data: {
          name: null,
          description: null,
          images: null,
          status: "1"
        },
        // ruleForm: {
        //   name: '',
        //   image: ''
        // },
        rules: {
          name: [
            { validator: checkName, trigger: 'blur' }
          ]
        }
      };
    },
	mounted() {
     this.setImageUploadUrl()
    if(this.galleryId){
      this.getGallery();
    }
  },
  computed: {
      loadEditor: function () {
        return !this.galleryId || (this.galleryId && this.data.description);
        }
  },
    methods: {
       updateTextAreaValue(value) {
        this.data.description = value;
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid && !this.galleryId) {
             return GalleryService.add(this.data).then(response => {
                // this.data = response.data
                this.showAlertAdded=true 
                return response
              });
          } else if (valid && this.galleryId) {
            return GalleryService.update(this.galleryId, this.data).then(response => {
				console.log(response);
              this.showAlertAdded=true;
              return response;

            });
          }else {
            console.log('error submit!!');
            return false;
          }
        });
      },handleFileUpload(){

      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
	backtogallery(){
		this.$router.push("/gallery");
	},
	getGallery(){
    return GalleryService.get(this.galleryId).then(response => {
        this.data = response.data.data;
        this.fileList.push({name:'', url: response.data.data.image});
        return response;
      });
	},
  setImageUploadUrl() {
        this.imageUploadHeaders = authHeader();
        this.imageUploadUrl = process.env.VUE_APP_BASE_API + 'image-upload';
      },
      handleImageUploadSuccess(response, file, fileList) {
        if(response.code == 200) {
          this.data.images = response.image_url;
        }
      },
      handleImageUploadError(err, file, fileList) {
        console.log(err)
      },
      handlePreview(file) {
        console.log(file)
        //TODO: Any Preview stuff
      },
      handleRemove(file, fileList) {
        console.log(file, fileList)
        //TODO: Delete the file
      },
   
  }
}
</script>
<style lang="scss" scoped>
.el-form--label-top .el-form-item__label{
      float: left !important;
      font-weight: 500;
      font-size: 12px;
      padding: 0 0 0px 0;
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }  
  .heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}
    .login {
        flex: 1;
		width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
   .login-form {
        width: 100%;
    }
    .selectstatus{
      -webkit-appearance: none;
    background-color: #FFFFFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: none;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    }
div .topsearch {
    float: right;
    display: inline-flex;
    padding: 15px 0px;
  }
div .topsearch .el-input__inner{
  padding-right:10px;
  height: 40px;
  width: 500px;
}
div .topsearch .el-input{
  padding-right:10px;
}
.pagination{ 
  padding: 12px 5px;
  float: right;
}
</style>