import BaseService from "./base.service";

class GalleryService {
    gallery(page = null, search = null) {
        let params = [];
        if(search) {
            params.push({'key':'q', 'value':search});
        }
        return BaseService.apiGetRequest('gallery', page, params);
    }
    get(id) {
        return BaseService.apiGetRequest('gallery/'+id);
    }
    delete(gallery) {
		return BaseService.apiPostRequest('gallery/delete/', gallery);
    }
    add(gallery) {
        return BaseService.apiPostRequest('gallery/add', gallery);
    }
    update(id, gallery) {
        return BaseService.apiPostRequest('gallery/'+id, gallery);
    }

    search(page=null) {
        return BaseService.apiGetRequest('gallery/search', page);
    }
}

export default new GalleryService();